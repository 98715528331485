type Props = {
  img: string;
  name: string;
};
export default function GalleryItem({ img, name }: Props) {
  return (
    <div className="w-full h-full relative overflow-hidden">
      <img
        src={img}
        className="object-cover inset-0 absolute -z-10 overflow-hidden"
        alt="galleries"
      />
      <div className="absolute inset-0 -z-10 bg-black/40" />
      <div className=" absolute inset-x-0 bottom-0 py-7 px-4 flex flex-col gap-y-2">
        <h2 className="font-bold text-lg">{name}</h2>
        <a href="#">View {">"}</a>
      </div>
    </div>
  );
}
