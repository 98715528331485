import Container from "../../../UI/Container";
import HnF from "../../../UI/HnF";

export default function Contact() {
  return (
    <div className="wrap relative overflow-x-hidden">
      <img
        src="/assets/v2/blur3.png"
        className="absolute right-0 bottom-[15%] w-96 -z-10"
        alt="bg-image"
      />
      <HnF>
        <Container>
          <div className="header pt-20 lg:pt-32 max-w-3xl flex flex-col gap-y-3">
            <h1 className="font-bold text-5xl text-white">Get in touch</h1>
            <div className="flex flex-col gap-4">
              <p className="font-thin text-gray-200">
                Check out our FAQ section for information about the NFT NG
                events & exhibitions including participation opportunities,
                registration details, and more.
              </p>
              <div className="flex flex-col">
                <h3 className="font-bold text-xl lg:text-2xl text-white">
                  Contact Customer Service For Merch Inquires
                </h3>
                <p className="text-gray-200">sales@nftng.io</p>
              </div>
              <div className="flex flex-col">
                <h3 className="font-bold text-xl lg:text-2xl text-white">
                  Contact Customer Service For Support
                </h3>
                <p className="text-gray-200">support@nftng.io</p>
              </div>
              <div className="flex flex-col">
                <h3 className="font-bold text-xl lg:text-2xl text-white">
                  Call to Speak to Our Representative
                </h3>
                <p className="text-gray-200">+1 584 543 0000</p>
              </div>
            </div>
          </div>

          {/* FAQ SEXTUIN */}
          <div className="pb-10 grid md:grid-cols-2 gap-16 mt-16">
            <div className="wrap">
              <form>
                <div className="grid grid-cols-2 gap-5">
                  <input
                    type="text"
                    className="border border-white border-opacity-15 rounded-xl p-3 bg-gray-200 placeholder:text-gray-900"
                    placeholder="Name"
                  />
                  <input
                    type="email"
                    className="border border-white border-opacity-15 rounded-xl p-3 bg-gray-200 placeholder:text-gray-900"
                    placeholder="Email"
                  />
                  <input
                    type="tel"
                    className="border border-white border-opacity-15 rounded-xl p-3 bg-gray-200 placeholder:text-gray-900"
                    placeholder="Phone"
                  />
                  <input
                    type="text"
                    className="border border-white border-opacity-15 rounded-xl p-3 bg-gray-200 placeholder:text-gray-900"
                    placeholder="Twitter Handle"
                  />
                  <textarea
                    className="border border-white border-opacity-15 rounded-xl p-3 bg-gray-200 placeholder:text-gray-900 col-span-2"
                    rows={5}
                    placeholder="Type your message"
                  />
                  <div className="wrap">
                    <button className="text-black bg-primary rounded-xl px-4 p-2">
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Container>
      </HnF>
    </div>
  );
}
