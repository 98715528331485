import HnF from "../../../UI/HnF";
import GalleryItems from "./GalleryItems";

export default function Gallery() {
  return (
    <div className="wrap text-gray-200">
      <HnF>
        <div className="max-w-6xl mx-auto px-3">
          <div className="lg:pt-32 pt-8 flex flex-col gap-y-8">
            <h1 className="font-bold text-4xl text-white">Gallery</h1>
            <GalleryItems />
          </div>
        </div>
      </HnF>
    </div>
  );
}
