import Container from "../../../UI/Container";

export default function AbtIntro() {
  return (
    <div className="wrap text-white pt-24 lg:pt-32 relative ">
      <img
        src="/assets/v2/blur3.png"
        className="absolute right-0 top-[0%] w-96 -z-10"
        alt="bg-image"
      />
      <Container>
        <div className="flex flex-col gap-4 relative z-10">
          <div className="wrap">
            <div className="wrap max-w-3xl">
              <h1 className="font-medium text-3xl lg:text-5xl my-7">
                We bring a wealth of skills and Experience <br /> from a wide
                range of background
              </h1>
              <span className=" opacity-50 text-sm">
                NFT NG harnesses Blockchain to ignite creativity and innovation
                across Africa, spotlighting the talent of the Web3 African
                community. We provide education, resources, and a platform for
                African creatives to thrive, collaborate, and lead. Our mission
                is to drive Web3 adoption and showcase Africa’s potential on the
                global stage.
              </span>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

// table booking and
// 200 early bed
// 900 early bed
