import HnF from "../../../UI/HnF";
import Faq from "./Faq";
import Football from "./Football";
import Intro from "./Intro";
import Mission from "./Mission";
import Partners from "./Partners";
import Socials from "./Socials";

export default function Home() {
  return (
    <div className="wrap">
      <img
        src="/assets/v2/blur1.png"
        alt="blur"
        className="absolute right-0 top-[150%]"
      />
      <div className="relative z-10">
        <HnF>
          <Intro />
          <Partners />
          <Mission />
          {/* <Team /> */}
          <Football />
          <Socials />
          {/* <AbtCard /> */}
          <Faq />
        </HnF>
      </div>
    </div>
  );
}
