import React from "react";
import { Link, NavLink } from "react-router-dom";

export default function Header() {
  const menuFunc = (link: string, title: string) => {
    return { link, title };
  };
  const menuArr = [
    menuFunc("/", "HOME"),
    menuFunc("/gallery", "GALLERY"),
    menuFunc("/about", "ABOUT US"),
    menuFunc("/buy/ticket", "TICKETS"),
    // menuFunc("/product", "PRODUCT"),
    // menuFunc("/event", "EVENT"),
    menuFunc("/contact", "CONTACT US"),
  ];
  return (
    <div className="hidden lg:block fixed w-full z-[100]">
      <div className="header bg-black bg-opacity-60 backdrop-blur-lg py-5 flex items-center justify-between px-10">
        <div className="logo-con">
          <Link to={"/"}>
            <img src="/assets/nftng-logo.png" alt="NFTng" />
          </Link>
        </div>
        <div className="menu-con">
          {menuArr.map((menu, index) => (
            <NavLink
              to={menu.link}
              className={({ isActive }) =>
                `capitalize decoration-none text-xs md:mx-4 lg:mx-4 text-slate-50 ${
                  isActive ? "border-b-2 border-primary" : ""
                }`
              }
              key={index}
            >
              {menu.title}
            </NavLink>
          ))}
        </div>

        <div className="btn-wrap">
          <button className="rounded-3xl p-2 text-xs bg-primary text-black min-w-[100px]">
            Login
          </button>
          <button className="rounded-3xl p-2 text-xs bg-primary text-black min-w-[100px] ml-3">
            Signup
          </button>
        </div>
      </div>
    </div>
  );
}
