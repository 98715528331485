import HnF from "../../../UI/HnF";
import Team from "../Homepage/Team";
import AbtIntro from "./AbtIntro";

export default function About() {
  return (
    <div className="wrap text-gray-200 overflow-x-hidden">
      <HnF>
        <AbtIntro />
        <Team />
      </HnF>
    </div>
  );
}
