import GalleryItem from "./GalleryItem";

export default function GalleryItems() {
  return (
    <div className="grid grid-cols-6 gap-4 py-4">
      <div className="col-span-3 lg:col-span-4 rounded-lg overflow-hidden h-56 md:h-64 lg:h-72">
        <GalleryItem img="/assets/galleries/g1.png" name="Defi Summer 2024" />
      </div>
      <div className="col-span-3 lg:col-span-2 rounded-lg overflow-hidden h-56 md:h-64 lg:h-72">
        <GalleryItem img="/assets/galleries/g2.png" name="The Sunset Soiree" />
      </div>
      <div className="col-span-3 lg:col-span-2 rounded-lg overflow-hidden h-56 md:h-64 lg:h-72">
        <GalleryItem img="/assets/galleries/g4.png" name="Summer Dinner" />
      </div>
      <div className="col-span-3 lg:col-span-4 rounded-lg overflow-hidden h-56 md:h-64 lg:h-72">
        <GalleryItem img="/assets/galleries/g3.png" name="Soccer Tournament" />
      </div>
    </div>
  );
}
